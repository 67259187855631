import request from "@/utils/request";

// 获取五十音分类
export function FiftyType() {
    return request({
        url: '/admin/japanese_v3/fiftyType',
        method: 'get'
    })
}

// 获取五十音列表
export function FiftyNotesList(params) {
    return request({
        url: '/admin/japanese_v3/fiftyNotesList',
        method: 'get',
        params
    })
}


// 获取五十音上传token
export function getFiftyCosToken(params) {
    return request({
        url: '/admin/japanese_v3/getFiftyCosToken',
        method: 'get',
        params
    })
}

// 获取编辑五十音
export function FiftyNotesEdit(params) {
    return request({
        url: '/admin/japanese_v3/fiftyNotesEdit',
        method: 'put',
        params
    })
}