<template>
  <div>
    <el-card style="margin-bottom: 100px">
      <div class="navs">
        <div class="headers">
          <ul>
            <li
              :class="{ selected: tabs == index }"
              @click="toggletabs(index, item.key)"
              v-for="(item, index) in fiftyTypeList"
              :key="index"
            >
              {{ item.value }}
            </li>
          </ul>
        </div>
      </div>
      <div class="contents">
        <el-table
          :data="fiftyNotesList"
          stripe
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            prop="hiragana"
            label="平假名"
            align="center"
          ></el-table-column>
          <el-table-column
            label="片假名"
            prop="katakana"
            align="center"
          ></el-table-column>
          <el-table-column label="罗马音" prop="rome" align="center">
          </el-table-column>
          <template v-if="type == '清音'">
            <el-table-column
              label="平假记忆"
              prop="hiragana_txt"
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="片假记忆"
              align="center"
              prop="katakana_txt"
            >
            </el-table-column>
          </template>

          <el-table-column label="视频" align="center" prop="video">
          </el-table-column>

          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="toEditFifty(scope.row)"
                size="small"
              ></el-button>

              <el-button
                type="success"
                icon="el-icon-video-play"
                circle
                @click="toVideoPlay(scope.row)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 五十音编辑 -->
    <el-dialog
      :visible.sync="editFiftyDialog"
      title="编辑五十音"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="editDialogClose"
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item el-form-item label="平假名" label-width="100px">
          <el-input
            v-model="editForm.hiragana"
            placeholder="请输入平假名"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item el-form-item label="片假名" label-width="100px">
          <el-input
            v-model="editForm.katakana"
            placeholder="请输入片假名"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item el-form-item label="罗马音" label-width="100px">
          <el-input
            v-model="editForm.rome"
            placeholder="请输入罗马音"
            disabled
          ></el-input>
        </el-form-item>
        <template v-if="isVoiceless">
          <el-form-item el-form-item label="平假联想记忆" label-width="100px">
            <el-input
              type="textarea"
              :rows="4"
              v-model="editForm.hiragana_txt"
              placeholder="请输入平假联想记忆"
            />
          </el-form-item>
          <el-form-item el-form-item label="平假缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeMemoryUpload"
            >
              <img
                v-if="editForm.hiragana_card_url"
                style="width: 100%"
                :src="editForm.hiragana_card_url"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item el-form-item label="片假联想记忆" label-width="100px">
            <el-input
              type="textarea"
              :rows="4"
              v-model="editForm.katakana_txt"
              placeholder="请输入片假联想记忆"
            />
          </el-form-item>
          <el-form-item el-form-item label="片假缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="456"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeKatakanaUpload"
            >
              <img
                v-if="editForm.katakana_card_url"
                style="width: 100%"
                :src="editForm.katakana_card_url"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>
        </template>
        <el-form-item el-form-item label="学习视频" label-width="100px">
          <video
            :src="editForm.video_url"
            style="width: 300px; height: 200px; cursor: pointer"
            autoplay
            v-if="editForm.video_url"
            @click="selectVideo"
            ref="videoPlay"
          ></video>
          <el-button type="primary" v-else @click="selectVideo"
            >选择视频文件</el-button
          ><span class="file-name">{{ fileVideoName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            name="video"
            id=""
            hidden
            ref="video"
            @change="selectVideoFiles"
            accept="video/*"
          />
        </el-form-item>
        <el-form-item label-width="40%">
          <el-button type="primary" @click="editFiftyNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 点击视频播放 -->
    <el-dialog
      :visible.sync="videoDialog"
      title="五十音视频"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="videoDialogClose"
    >
      <div>
        <video
          :src="videoDetailsSrc"
          style="width: 100%; height: 520px; cursor: pointer"
          autoplay
          controls
          ref="videoDetails"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  FiftyType,
  FiftyNotesList,
  getFiftyCosToken,
  FiftyNotesEdit,
} from "@/api/Japanese_app/fifty_notes";
import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";

export default {
  data() {
    return {
      fiftyTypeList: [],
      tabs: 0,
      type: "",
      fiftyNotesList: [],
      editFiftyDialog: false,
      editForm: {},
      progress: 0,
      fileVideoName: "",
      isVoiceless: true,
      videoDialog: false,
      videoDetailsSrc: "",
    };
  },
  created() {
    this.getFiftyType();
  },
  methods: {
    // 视频播放弹框关闭事件
    videoDialogClose() {
      if (this.$refs.videoDetails !== undefined) {
        this.$refs.videoDetails.pause();
      }
    },

    // 点击操作栏的视频播放按钮
    toVideoPlay(item) {
      if (item.video == "" || item.video_url == "") {
        this.$message.error("暂无视频,亲请上传视频后重试~");
        return;
      }
      this.videoDetailsSrc = item.video_url;
      this.videoDialog = true;
    },

    // 获取tab分类
    getFiftyType() {
      FiftyType().then((res) => {
        this.fiftyTypeList = res.data;
        this.type = res.data[0].key;
        this.getFiftyNotesList();
      });
    },
    // tab栏切换
    toggletabs(index, type) {
      this.isVoiceless = true;
      if (type !== "清音") {
        this.isVoiceless = false;
      }
      this.tabs = index;
      this.type = type;
      this.getFiftyNotesList();
    },

    // 获取五十音列表
    getFiftyNotesList() {
      FiftyNotesList({ type: this.type }).then((res) => {
        this.fiftyNotesList = res.data;
      });
    },

    // 点击编辑图标
    toEditFifty(item) {
      let data = JSON.parse(JSON.stringify(item));
      this.editForm = data;
      this.editFiftyDialog = true;
    },

    // 修改弹窗关闭触发
    editDialogClose() {
      if (this.$refs.videoPlay !== undefined) {
        this.$refs.videoPlay.pause();
      }
    },

    // 选择图片
    beforeMemoryUpload(file) {
      const isSize = file.size / 1024 / 1024 < 1;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 1MB!");
        return;
      }
      let that = this;
      getFiftyCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.editForm.hiragana_card = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.editForm.hiragana_card_url = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    beforeKatakanaUpload(file) {
      const isSize = file.size / 1024 / 1024 < 1;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 1MB!");
        return;
      }
      let that = this;
      getFiftyCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.editForm.katakana_card = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.editForm.katakana_card_url = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击选择视频文件按钮
    selectVideo() {
      this.$refs.video.click();
    },

    // 选择了视频文件
    selectVideoFiles() {
      let file = this.$refs.video.files[0];
      if (this.$refs.video.files.length == 0) {
        this.fileVideoName = "";
        return;
      }
      this.fileVideoName = file.name;
      if (file.size / 1024 / 1024 > 30) {
        this.$message.error("音频不能大于 30M ");
        return;
      }
      getFiftyCosToken({ suffix: file.name }).then((res) => {
        this.CosObj = res.data;
        var that = this;
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: that.CosObj.Bucket,
            Region: that.CosObj.Region,
            Key: that.CosObj.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
              that.editForm.video = that.CosObj.key;
              that.editForm.video_url = "";
            },
          },
          function (err, data) {
            console.log(err || data);
          }
        );
      });
    },

    // 点击立即修改
    editFiftyNow() {
      let data = {
        hiragana: this.editForm.hiragana,
        hiragana_card: this.editForm.hiragana_card,
        hiragana_txt: this.editForm.hiragana_txt,
        katakana_card: this.editForm.katakana_card,
        katakana_txt: this.editForm.katakana_txt,
        video: this.editForm.video,
      };
      FiftyNotesEdit(data).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          this.progress = 0;
          this.fileVideoName = "";
          return;
        }
        this.$message.success("修改成功!");
        this.editFiftyDialog = false;
        this.progress = 0;
        this.fileVideoName = "";
        this.getFiftyNotesList();
      });
    },
  },
};
</script>

<style scoped lang="less">
.avatar {
  z-index: 999;
}

* {
  box-sizing: border-box;
}
li {
  list-style: none;
  cursor: pointer;
}
.navs {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  padding: 0 20px;
  .headers {
    border-bottom: 1px solid #eeeeee;
    height: 120px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    ul {
      width: 100%;
      li {
        height: 50px;
        float: left;
        margin-right: 60px;
      }
    }
    .selected {
      color: #38a28a;
      border-bottom: 1px solid #38a28a;
    }
  }
}
.contents {
  margin-top: 10px;
}
</style>
